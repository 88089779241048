// eslint-disable-next-line no-redeclare,no-unused-vars
function optionsField(param) {
  var row = $.extend(true, {}, param.row);
  var settings = $.extend(true, {}, param.settings);
  var object = param.object;
  var objectSub = param.objectSub;
  var rowRole = {};

  $.each(settings, function (option) {
    // objectSub
    if (objectSub && param.parameter && !inArray(option, globalOptions))
      row[option] = row[option + ucfirst(param.objectSub)];
    else if (objectSub && !inArray(option, ['type', 'unique'])) {
      settings[option].field5 = 'button';
      settings[option].iconField5 = 'share-alt';
      settings[option].tipBtnField5 = 'nur für ' + param.objectSub;
      settings[option].class = 'objectSub';
      settings[option].funcField5 = function (btn, element, line) {
        btn.toggleClass('active');
        if (btn.hasClass('active')) line.objectSub = true;
        else line.objectSub = false;
        var opt = option;
        if (line.objectSub) opt += ucfirst(param.objectSub);
        if (line.lang == 'en') opt += 'En';
        row[option] = param.row[opt];
        if (!row[option]) row[option] = '';
        element.val(row[option]);
      };
      var optionSub = option + ucfirst(objectSub);
      if (row[optionSub]) settings[option].triggerFuncField5 = true;
    }

    // language
    if (
      (!settings[option].field ||
        inArray(settings[option].field, ['input', 'textarea'])) &&
      !inArray(option, ['type'])
    ) {
      settings[option].field6 = 'button';
      settings[option].iconField6 = 'globe';
      settings[option].tipBtnField6 = 'english';
      settings[option].class = 'english';
      settings[option].funcField6 = function (btn, element, line) {
        btn.toggleClass('active');
        if (btn.hasClass('active')) line.lang = 'en';
        else delete line.lang;
        var opt = option;
        if (line.objectSub) opt += ucfirst(param.objectSub);
        if (line.lang == 'en') opt += 'En';
        row[option] = param.row[opt];
        if (!row[option]) row[option] = '';
        element.val(row[option]);
      };
      if (user.language == 'en')
        settings[option].callback = function (line) {
          line.els[1].trigger(click);
        };
    }

    // read
    if (isPlainObject(row[option]))
      $.each(row[option], function (i, v) {
        if (isPlainObject(v)) row[option][i] = JSON.stringify(v);
      });

    // role
    if (param.role && row[option + param.role])
      rowRole[option] = row[option + param.role];

    // special values
    if (inArray(option, parameter.admin.assocFields)) {
      var keySettings = {field: 'input'};
      // if (inArray(option, ['disable', 'hide', 'hideBlock', 'hideTab']) && value.values) {
      // 	keySettings = {field: 'select', values: [], sort: 1};
      // 	$.each(value.values, function (i, v) {
      // 		keySettings.values.push(v);
      // 		keySettings.values.push('!' + v);
      // 	});
      // }
      // else if (inArray(option, ['restrict', 'where', 'adopt', 'warningRed', 'warningYellow', 'warningGreen'])) {
      // 	keySettings = {
      // 		field: 'select',
      // 		values: [],
      // 		labels: [],
      // 		sort: 1
      // 	};
      // 	$.each(fields[param.object], function (k, s) {
      // 		keySettings.values.push(k);
      // 		keySettings.labels.push(s.label ? s.label + ' (' + k + ')' : k);
      // 	});
      // }
      var valueSettings = {};
      if (inArray(option, ['disable', 'hide'])) {
        valueSettings = {
          field: 'select',
          multi: true,
          values: [],
          labels: [],
          sort: 1,
        };
        $.each(fields[param.object], function (k, s) {
          valueSettings.values.push(k);
          valueSettings.labels.push(s.label ? s.label + ' (' + k + ')' : k);
        });
      } else if (inArray(option, ['hideBlock', 'hideTab'])) {
        valueSettings = {
          field: 'select',
          multi: true,
          values: [],
          labels: [],
          sort: 1,
        };
        var form = optionValue({
          settings: parameter[param.object],
          objectSub: param.objectSub,
          key: 'form',
        });
        if (!isArray(form)) form = parameter[param.object][form];
        var typeChar = '-';
        if (option == 'hideTab') typeChar = '|';
        $.each(form, function (i, k) {
          if (k.slice(0, 1) == typeChar) {
            k = k.slice(1);
            if (k.indexOf('#') > 0) k = k.slice(0, k.indexOf('#'));
            valueSettings.values.push(k);
            if (getObjectValue(parameter, [object, 'labels', k]))
              valueSettings.labels.push(parameter[object].labels[k]);
            else valueSettings.labels.push(k);
          }
        });
      }
      settings[option].edit = {key: keySettings, value: valueSettings};
    }

    // sorter
    if (inArray(option, ['tableFields', 'tableEdit']))
      settings[option].func = function (btn, element) {
        var pm = {title: option, top: param.top.sub, list: row[option]};
        if (row.reference) pm.fields = fields[row.reference];
        pm.save = function (value) {
          element.val(value);
          element.save();
        };
        sorter(pm);
      };

    // table values
    // if (value.tableFields && arrayEqual(value.tableFields, ['key', 'value']) && row[option] && isPlainObject(row[option])) {
    // 	var val = [];
    // 	$.each(row[option], function (k, v) {
    // 		val.push({key: k, value: v});
    // 	});
    // 	row[option] = val;
    // }
  });
  if (param.role) row = rowRole;
  return [row, settings];
}
